import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import * as Realm from 'realm-web';

import { useMSTeamsApp } from './MSTeamsAppProvider';
import { BUILD_FOR_TEAMS } from './appConstants';

const RealmAppContext = React.createContext();

export const useRealmApp = () => {
  const app = useContext(RealmAppContext);
  if (!app) {
    throw new Error(
      'You must call useRealmApp() inside of a <RealmAppProvider />'
    );
  }
  return app;
};

export const RealmAppProvider = ({ appId = '', children = undefined }) => {
  const [app] = useState(new Realm.App(appId));
  // eslint-disable-next-line no-unused-vars
  const [errorMsg, setErrorMsg] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  // Wrap the Realm.App object's user state with React state
  const [currentUser, setCurrentUser] = useState(app.currentUser);

  const { SSOToken, userInfo } = useMSTeamsApp();

  const logIn = useCallback(async (credentials) => {
    const user = await app.logIn(credentials);
    // If successful, app.currentUser is the user that just logged in
    setCurrentUser(app.currentUser);
    return user;
  }, [app]);

  const logOut = useCallback(async () => {
    // Log out the currently active user
    await app.currentUser?.logOut();
    // If another user was logged in too, they're now the current user.
    // Otherwise, app.currentUser is null.
    setCurrentUser(app.currentUser);
  }, [app]);

  const removeUser = useCallback(async (user) => {
    await app.removeUser(user);
  }, [app]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (SSOToken && SSOToken.token) {
          await logIn(Realm.Credentials.function({
            SSOToken: SSOToken.token, userInfo
          }));
          setLoading(false);
        }
      } catch (err) {
        setErrorMsg(err.error);
        setLoading(false);
      }
    })();
  }, [SSOToken, logIn, userInfo]);

  const wrapped = useMemo(() => ({
    ...app,
    currentUser,
    logIn,
    logOut,
    removeUser
  }), [
    app,
    currentUser,
    logIn,
    logOut,
    removeUser
  ]);

  return (
    <RealmAppContext.Provider value={wrapped}>
      {
        // eslint-disable-next-line no-nested-ternary
        BUILD_FOR_TEAMS ? (app.currentUser?.customData ? children : null) : children
      }
    </RealmAppContext.Provider>
  );
};

RealmAppProvider.propTypes = {
  appId: PropTypes.string,
  children: PropTypes.node
};
