import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Icon } from 'react-bulma-components';
import {
  FaEye, FaEyeSlash, FaLock
} from 'react-icons/fa';

/**
 * Password input field - to be used in forms
 */
const InputPassword = ({ value, placeHolder, name, handleChange, id }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <Form.Field>
      <Form.Control>
        <Form.Input
          id={id}
          placeholder={placeHolder}
          type={showPassword ? 'text' : 'password'}
          value={value}
          name={name}
          required
          onChange={handleChange}
        />
        <Icon align="left" size="small">
          <FaLock />
        </Icon>
        <Icon align="right" size="small" clickable onClick={handleClick}>
          {showPassword ? <FaEye /> : <FaEyeSlash />}
        </Icon>
      </Form.Control>
    </Form.Field>
  );
};

export default InputPassword;

InputPassword.propTypes = {
  /**
   * Value from state to be displayed in input
   */
  value: PropTypes.string,
  /**
   * Placeholder text for input.
   */
  placeHolder: PropTypes.string,
  /**
   * Unique name to identify input
   */
  name: PropTypes.string,
  /**
   * Function to update state when current input onChange event fired
   */
  handleChange: PropTypes.func,
  /**
   * Unique id to identify input
   */
  id: PropTypes.string
};

InputPassword.defaultProps = {
  value: '',
  placeHolder: 'Password',
  name: 'password',
  handleChange: undefined,
  id: 'password'
};
