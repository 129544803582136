import React, { lazy, Suspense } from 'react';
import { RealmAppProvider } from 'App/RealmAppProvider';
import RequireLoggedInUser from 'App/RequireLoggedInUser';
import RealmApolloProvider from 'App/RealmApolloProvider';
import APP_ID, { BUILD_FOR_TEAMS } from 'App/appConstants';
// import Routing from 'Routing/Routing';
import Loader from 'Loader/Loader';
import RequireCustomData from 'App/RequireCustomData';
import MSTeamsAppProvider from './MSTeamsAppProvider';

const Routing = lazy(() => import('Routing/Routing'));

// eslint-disable-next-line no-confusing-arrow
const App = () => BUILD_FOR_TEAMS ? (
  <MSTeamsAppProvider>
    <RealmAppProvider appId={APP_ID}>
      <RequireLoggedInUser>
        <RealmApolloProvider>
          <Suspense fallback={<Loader />}>
            <Routing />
          </Suspense>
        </RealmApolloProvider>
      </RequireLoggedInUser>
    </RealmAppProvider>
  </MSTeamsAppProvider>
) : (
  <RealmAppProvider appId={APP_ID}>
    <RequireLoggedInUser>
      <RequireCustomData>
        <RealmApolloProvider>
          <Suspense fallback={<Loader />}>
            <Routing />
          </Suspense>
        </RealmApolloProvider>
      </RequireCustomData>
    </RequireLoggedInUser>
  </RealmAppProvider>
);

export default App;
