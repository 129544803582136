import React, {
  useEffect, useState, useMemo, useContext
} from 'react';
import { useTeamsUserCredential } from '@microsoft/teamsfx-react';
import { app as MSapp } from '@microsoft/teams-js';
import { MS_TEAMS_CLIENT_ID, MS_TEAMS_INITIATE_LOGIN_ENDPOINT } from './appConstants';

const MSTeamsAppContext = React.createContext(
  {
    theme: undefined,
    themeString: '',
    teamsUserCredential: undefined,
    userInfo: undefined,
    SSOToken: undefined,
    initialized: false
  }
);

export const useMSTeamsApp = () => {
  const app = useContext(MSTeamsAppContext);
  if (!app) {
    throw new Error(
      'You must call useMSTeamsApp() inside of a <MSTeamsAppProvider />'
    );
  }
  return app;
};

const MSTeamsAppProvider = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const [initError, setInitError] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [SSOToken, setSSOToken] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [dataError, setDataError] = useState(null);

  useEffect(() => {
    MSapp.initialize()
      .then(() => {
        setInitialized(true);
        console.log('Teams SDK initialized');
      })
      .catch((error) => {
        setInitError(error);
        console.error('Failed to initialize Teams SDK', error);
      });
  }, []);

  const { theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: MS_TEAMS_INITIATE_LOGIN_ENDPOINT,
    clientId: MS_TEAMS_CLIENT_ID
  });

  useEffect(() => {
    if (initialized && teamsUserCredential) {
      setDataLoading(true);
      const fetchData = async () => {
        try {
          const thisUserInfo = await teamsUserCredential.getUserInfo();
          const thisSSOToken = await teamsUserCredential.getSSOToken();
          setUserInfo(thisUserInfo);
          setSSOToken(thisSSOToken);
        } catch (error) {
          setDataError(error);
          console.error('Failed to fetch user data:', error);
        } finally {
          setDataLoading(false);
        }
      };
      fetchData();
    }
  }, [initialized, teamsUserCredential]); // Depend on initialized state and user credentials

  const wrapped = useMemo(() => ({
    theme,
    themeString,
    teamsUserCredential,
    userInfo,
    SSOToken,
    initialized
  }), [
    theme,
    themeString,
    teamsUserCredential,
    userInfo,
    SSOToken,
    initialized
  ]);

  if (!initialized || dataLoading) {
    return null;
  }

  if (initError) {
    return (
      <div>
        Error initializing Teams SDK:
        {initError.message}
      </div>
    );
  }

  if (dataError) {
    return (
      <div>
        Error loading user data:
        {dataError.message}
      </div>
    );
  }

  console.log('theme', theme);
  console.log('themeString', themeString);
  console.log('teamsUserCredential', teamsUserCredential);
  console.log('User Info:', userInfo);
  console.log('SSO Token:', SSOToken);

  return (
    <MSTeamsAppContext.Provider value={wrapped}>
      {children}
    </MSTeamsAppContext.Provider>
  );
};

export default MSTeamsAppProvider;
