export const BUILD_FOR_TEAMS = process.env.REACT_APP_BUILD_FOR_TEAMS === 'true';

export const MS_TEAMS_CLIENT_ID = process.env.REACT_APP_MS_TEAMS_CLIENT_ID;

// eslint-disable-next-line max-len
export const MS_TEAMS_INITIATE_LOGIN_ENDPOINT = process.env.REACT_APP_MS_TEAMS_INITIATE_LOGIN_ENDPOINT;

const APP_ID = process.env.REACT_APP_REALM_APP_ID;

export default APP_ID;
